import { Injectable, inject } from '@angular/core';
import { LimitType } from './user/data/user-limits.data';
import { CookieService } from 'ngx-unificator/services';
import { CurrencyConverterService } from './currency-converter.service';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';
import { PlatformService } from './platform.service';

declare var dataLayer: any[];

enum ACTIONS {
  LOGIN_ACTION = 'login',
  REGISTER_ACTION = 'register',
  CLICK_ACTION = 'click',
}

enum CATEGORIES {
  FORM_SUBMIT = 'form_submit',
  DEPOSIT_START = 'deposit_start',
  LIMIT = 'limit',
  ABTEST = 'ab-test'
}

enum TRANSACTIONS {
  DEPOSIT = 'deposit',
  CASHOUT = 'cashout',
}

export enum POPUP_EVENT_CATEGORY {
  SLOT_WELCOME_POPUP = 'popup_slot_welcome',
  POPUP_DEPOSIT = 'popup_deposit',
  POPUP_LOW_BALANCE = 'popup_low_balance',
  POPUP_REGISTRATION = 'popup_registration',
  POPUP_DEPOSIT_SUCCESS = 'popup_deposit_success',
  POPUP_AUTH_FIELDS_MISSED_UPDATE = 'popup_auth_fields_missed_update',
  BONUS_CANCEL_CONFIRMATION = 'popup_bonus_cancel_confirmation',
  DEPOSIT_BONUS_INFO = 'popup_deposit_bonus_info',
  DEPOSIT_MODAL = 'popup_deposit_modal',
  GAME_NOT_AVAILABLE = 'popup_game_not_available',
  GAME_REQUIRES_FLASH = 'popup_game_requires_flash',
  BONUS_LIST_MODAL = 'popup_bonus_list_modal',
  BONUS_MODAL = 'popup_bonus_modal',
  BONUS_TERMS_MODAL = 'popup_bonus_terms_modal',
  CHANGE_PASSWORD_FORM = 'popup_change_password_form',
  CLAIM_BONUS = 'popup_claim_bonus',
  CONTACTS_FORM = 'popup_contacts_form',
  EDIT_DOCUMENT_FORM = 'popup_edit_document_form',
  JACKPOT_RULES = 'popup_jackpot_rules',
  JACKPOT_WINNERS_LIST_MODAL = 'popup_jackpot_winners_list_modal',
  JACKPOT_WINNING = 'popup_jackpot_winning',
  LEAVE_REGISTER_MODAL = 'popup_leave_register_modal',
  LIMITS_REACHED = 'popup_limits_reached',
  LOOTBOX = 'popup_lootbox',
  NO_PAYMENTS_MODAL = 'popup_no_payments_modal',
  PASSWORD_RESTORE_FORM = 'popup_password_restore_form',
  PRIZEPOOLS_MODAL = 'popup_prizepools_modal',
  RESTORATION_INSTRUCTION_FORM = 'popup_restoration_instruction_form',
  SYS_NOTIFICATION = 'popup_notification',
  TITLE_AND_CONTENT_MODAL = 'popup_title_and_content_modal',
  GROUP_TOURNAMENTS = 'popup_group_tournaments',
  TOURNAMENT_MODAL = 'popup_tournament_modal',
  EXCHANGE_POINT_MODAL = 'popup_exchange_point_modal'
}

export enum POPUP_EVENT_ACTIONS {
  SLOT_WELCOME_POPUP = 'open_popup_slot_welcome',
  POPUP_DEPOSIT = 'open_popup_deposit',
  POPUP_LOW_BALANCE = 'open_popup_low_balance',
  POPUP_REGISTRATION = 'open_popup_registration',
  POPUP_DEPOSIT_SUCCESS = 'open_popup_deposit_success',
  POPUP_AUTH_FIELDS_MISSED_UPDATE = 'open_popup_auth_fields_missed_update',
  BONUS_CANCEL_CONFIRMATION = 'open_popup_bonus_cancel_confirmation',
  DEPOSIT_BONUS_INFO = 'open_popup_deposit_bonus_info',
  DEPOSIT_MODAL = 'open_popup_deposit_modal',
  GAME_NOT_AVAILABLE = 'open_popup_game_not_available',
  GAME_REQUIRES_FLASH = 'open_popup_game_requires_flash',
  BONUS_LIST_MODAL = 'open_popup_bonus_list_modal',
  BONUS_MODAL = 'open_popup_bonus_modal',
  BONUS_TERMS_MODAL = 'open_popup_bonus_terms_modal',
  CHANGE_PASSWORD_FORM = 'open_popup_change_password_form',
  CLAIM_BONUS = 'open_popup_claim_bonus',
  CONTACTS_FORM = 'open_popup_contacts_form',
  EDIT_DOCUMENT_FORM = 'open_popup_edit_document_form',
  JACKPOT_RULES = 'open_popup_jackpot_rules',
  JACKPOT_WINNERS_LIST_MODAL = 'open_popup_jackpot_winners_list_modal',
  JACKPOT_WINNING = 'open_popup_jackpot_winning',
  LEAVE_REGISTER_MODAL = 'open_popup_leave_register_modal',
  LIMITS_REACHED = 'open_popup_limits_reached',
  LOOTBOX = 'open_popup_lootbox',
  NO_PAYMENTS_MODAL = 'open_popup_no_payments_modal',
  PASSWORD_RESTORE_FORM = 'open_popup_password_restore_form',
  PRIZEPOOLS_MODAL = 'open_popup_prizepools_modal',
  RESTORATION_INSTRUCTION_FORM = 'open_popup_restoration_instruction_form',
  SYS_NOTIFICATION = 'open_popup_notification',
  TITLE_AND_CONTENT_MODAL = 'open_popup_title_and_content_modal',
  GROUP_TOURNAMENTS = 'open_popup_group_tournaments',
  TOURNAMENT_MODAL = 'open_popup_tournament_modal',
  EXCHANGE_POINT_MODAL = 'popup_exchange_point_modal'
}


enum GTAG_COOKIES {
  FIRST_DEPOSIT_SENT = 'gtm-first-deposit-sent',
}

const TIMESTAMP = +new Date();

export interface GoogleTagManagerConfig {
  id: string | null;
  gtm_auth?: string;
  gtm_preview?: string;
  [key: string]: string | null | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  private _cookie = inject(CookieService);
  private _currency = inject(CurrencyConverterService);
  private _platform = inject(PlatformService);
  private _window = inject<Window>(WINDOW);
  private _document = inject<Document>(DOCUMENT);

  private _isLoaded = false;
  public config: GoogleTagManagerConfig = { id: 'GTM-TCLWQCK' };

  /**
   * List of already focused fields
   */
  private _firstFocusEventList = new Map<any, any>();

  constructor() {
    if (typeof dataLayer !== 'undefined' && dataLayer) {
      dataLayer = (window as any).dataLayer = (window as any).dataLayer || [];
    }
  }

  public addGtmToDom() {
    return new Promise((resolve, reject) => {
      if (this._isLoaded) {
        return resolve(this._isLoaded);
      }
      const doc = this._document;
      this._addDataLayer({
        'gtm.start': new Date().getTime(),
        'event': 'gtm.js',
      });
      const gtmScript = doc.createElement('script');
      gtmScript.id = 'GTMscript';
      gtmScript.async = true;
      gtmScript.src = this.applyGtmQueryParams(
        this.config.gtm_resource_path ? this.config.gtm_resource_path : 'https://www.googletagmanager.com/gtm.js',
      );
      doc.head.insertBefore(gtmScript, doc.head.firstChild);
      if (this._platform.isBrowser) {
        gtmScript.addEventListener('load', () => {
          return resolve((this._isLoaded = true));
        });
        gtmScript.addEventListener('error', () => {
          return reject(false);
        });
      } else {
        return resolve((this._isLoaded = true));
      }
    });
  }

  public getDataLayer(): any[] {
    const window = this._window;
    window['dataLayer'] = window['dataLayer'] || [];
    return window['dataLayer'];
  }

  private _addDataLayer(data: object) {
    const dataLayer = this.getDataLayer();
    dataLayer.push(data);
  }

  private applyGtmQueryParams(url: string): string {
    if (url.indexOf('?') === -1) {
      url += '?';
    }

    return (
      url +
      Object.keys(this.config)
        .filter(k => this.config[k])
        .map(k => `${k}=${this.config[k]}`)
        .join('&')
    );
  }


  public lowBalancePopupStart(eventName: string, playerId: number) {
    this._addDataLayer({
      event: eventName,
      event_label: 'popup_start',
      event_action: 'low_balance',
      event_category: 'popup',
      dimension1: playerId,
    });
  }

  public lowBalancePopupClose(eventName: string, playerId: number) {
    this._addDataLayer({
      event: 'popup_close',
      event_label: 'popup_close',
      event_action: 'low_balance',
      event_category: 'popup',
      dimension1: playerId,
    });
  }

  public popupEvent(eventCategory: POPUP_EVENT_CATEGORY, eventAction: POPUP_EVENT_ACTIONS) {
    this._addDataLayer({
      event: 'samuraipopup',
      event_action: eventAction,
      event_category: eventCategory
    });
  }

  /**
   * SignIn header click
   * @eventName event name
   * @label place where user click
   */
  public signInHeaderClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * SignIn sidebar Click
   * @param {string} eventName
   * @param {string} label
   */
  public signInSidebarClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Sidebar account click
   * @param {string} eventName
   * @param {string} label
   */
  public sidebarAccountClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Sidebar bonuses click
   * @param {string} eventName
   * @param {string} label
   */
  public sidebarBonusesClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Slider bonuses click
   * @param {string} eventName
   * @param {string} label
   */
  public sliderBonusesClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Login button on game page click
   * @param {string} eventName
   * @param {string} label
   */
  public loginButtonOnGamePageClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Game try real click
   * @param {string} eventName
   * @param {string} label
   */
  public loginGameTryRealClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Favorite game click
   * @param {string} eventName
   * @param {string} label
   */
  public favoriteGameClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Login on Registration form click
   * @param {string} eventName
   * @param {string} label
   */
  public loginOnRegistrationClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Login on Registration form click
   * @param {string} eventName
   * @param {string} label
   */
  public loginOnHoverGameClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Login click on banner mobile
   * @param {string} eventName
   * @param {string} label
   */
  public loginClickOnBannerMobile(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Sign in success
   * @userId
   */
  public signInSuccess(userId) {
    this._addDataLayer({
      event: 'login_success',
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: 'login_success',
      dimension1: userId
    });
  }

  /**
   * Register header click
   * @param {string} eventName
   * @param {string} label
   */
  public registerHeaderClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Register sidebar click
   * @param {string} eventName
   * @param {string} label
   */
  public registerSidebarClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Register sidebar click
   * @param {string} eventName
   * @param {string} label
   */
  public registerMainBannerClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Register sidebar click
   * @param {string} eventName
   * @param {string} label
   */
  public registerLoginFormrClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Register form inputs focus
   * @param {string} label
   */
  public registerFormInputFocus(label: string) {
    const eventVal = {
      event: 'register',
      eventAction: 'focus',
      eventCategory: 'register',
      eventLabel: label
    };
    const eventKey = Object.values(eventVal).join();
    if (!this._firstFocusEventList.has(eventKey)) {
      this._firstFocusEventList.set(eventKey, true);
      this._addDataLayer(eventVal);
    }
  }

  /**
   * On loyalty page click
   * @param {string} eventName
   * @param {string} label
   */
  public loyaltyPageClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * On sticky bottom login click
   * @param {string} eventName
   * @param {string} label
   */
  public stickyBottomButtonClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * signUp Success
   * @userId
   */
  public signUpSuccess(userId) {
    this._addDataLayer({
      event: 'register_success',
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: 'signup-success',
      dimension1: userId
    });
  }

  /**
   * Click on deposit header button
   * @param {string} eventName
   * @param {string} label
   * @param userId
   */
  public depositHeaderButton(eventName: string, label: string, userId) {
    this._addDataLayer({
      event: eventName,
      event_action: TRANSACTIONS.DEPOSIT,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  /**
   * Click on deposit header button
   * @param {string} eventName
   * @param {string} label
   * @param userId
   */
  public depositSidenavClick(eventName: string, label: string, userId) {
    this._addDataLayer({
      event: eventName,
      event_action: TRANSACTIONS.DEPOSIT,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  /**
   * Click on deposit button on game page
   * @param {string} eventName
   * @param {string} label
   * @param userId
   */
  public depositGamePageClick(eventName: string, label: string, userId) {
    this._addDataLayer({
      event: eventName,
      event_action: TRANSACTIONS.DEPOSIT,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  /**
   * Click on deposit button on banking page
   * @param {string} eventName
   * @param {string} label
   * @param userId
   */
  public depositBankingPageClick(eventName: string, label: string, userId) {
    this._addDataLayer({
      event: eventName,
      event_action: TRANSACTIONS.DEPOSIT,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  /**
   * Click on deposit vendor
   * @param {string} eventName
   * @param {string} label
   * @param {string} method
   * @param userId
   */
  public depositVendorClick(eventName: string, label: string, method: string, userId) {
    this._addDataLayer({
      event: eventName,
      event_action: method,
      event_category: CATEGORIES.DEPOSIT_START,
      event_label: method,
      dimension1: userId
    });
  }

  /**
   * Click on get bonus tournament
   * @param {string} eventName
   * @param {string} label
   */
  public getBonusTournamentClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.CLICK_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Page 403
   * @param {string} eventName
   * @param referrer
   * @param country
   */
  public page403(eventName: string, referrer, country) {
    this._addDataLayer({
      event: eventName,
      event_action: country,
      event_category: 'error_403',
      event_label: referrer,
    });
  }

  /**
   * Page 403
   * @param {string} eventName
   * @param pageLocation
   */
  public page404(eventName: string, pageLocation) {
    this._addDataLayer({
      event: eventName,
      event_action: 'not_found',
      event_category: 'error_404',
      event_label: pageLocation,
    });
  }

  /**
   * Not available pop-up game click
   * @param gameUrl
   * @param eventName
   */
  public gameNotAvailableClick(eventName: string, gameUrl) {
    this._addDataLayer({
      event: eventName,
      event_action: 'not_found',
      event_category: 'error',
      event_label: gameUrl,
    });
  }

  /**
   * Register error event
   * @param {string} eventName
   * @param error
   */
  public registerError(eventName: string, error) {
    this._addDataLayer({
      event: eventName,
      event_action: 'register_error',
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: error,
    });
  }

  /**
   * Provider click
   * @param {string} eventName
   * @param {string} providerName
   */
  public providerClick(eventName: string, providerName: string) {
    this._addDataLayer({
      event: eventName,
      event_action: 'select',
      event_category: 'filter_provider',
      event_label: providerName,
    });
  }

  /**
   * Search game event
   * @param {string} eventName
   * @param {string} value
   */
  public searchGame(eventName: string, value: string) {
    this._addDataLayer({
      event: eventName,
      event_action: 'enter',
      event_category: 'search_game',
      event_label: value,
    });
  }

  public updateProfileModalSubmitClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  public updateProfileModalSubmitSuccessClick(eventName: string, label: string, userId: number) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  public accountDepositButtonClick(eventName: string, label: string, userId: number) {
    this._addDataLayer({
      event: eventName,
      event_action: 'deposit',
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  public accountActivatedClick(eventName: string, label: string, userId: number) {
    this._addDataLayer({
      event: eventName,
      event_action: 'activate',
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  public installAppClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: 'install',
      event_category: 'app',
      event_label: label,
    });
  }

  /**
   * Responsible game limits event
   * @param type
   * @param period
   * @param userId
   */
  public limitsEvent(type, period, userId) {
    switch (type) {
      case LimitType.DEPOSIT:
        this._addDataLayer({
          event: 'deposit_limits',
          event_action: 'deposit',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
      case LimitType.WAGER:
        this._addDataLayer({
          event: 'wager_limits',
          event_action: 'wager',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
      case LimitType.LOSS:
        this._addDataLayer({
          event: 'loss_limits',
          event_action: 'loss',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
      case LimitType.SESSION:
        this._addDataLayer({
          event: 'session_limit',
          event_action: 'session',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
      case LimitType.COOLINGOFF:
        this._addDataLayer({
          event: 'cooling_off',
          event_action: 'cooling_off',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
      case LimitType.SELFEXCLUSION:
        this._addDataLayer({
          event: 'self_exclusion',
          event_action: 'self_exclusion',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
    }
  }

  public categoriesEvents(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: 'select',
      event_category: 'filter_category',
      event_label: label,
    });
  }

  public onLiveChatClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: 'live',
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * signUp Success Timestamp
   * @userId
   */
  public signUpSuccessTimestamp(eventName: string, label: string, userId: number) {
    this._addDataLayer({
      event: eventName,
      event_action: TIMESTAMP,
      event_category: 'timestamp',
      event_label: label,
      dimension1: userId
    });
  }

  /**
   * Transaction result
   * @param transaction
   * @param transactionsList
   * @param user
   */
  public transactionResult(transaction, transactionsList, user) {
    if (this._checkExistTransaction(transaction)) {
      if (transaction.success) {
        this._successTransaction(transaction, user);
        if (transaction.action === TRANSACTIONS.DEPOSIT) {
          const depNumber = transactionsList.filter(tr => tr.success).indexOf(transaction) + 1;
          this.depositNumber(transaction, depNumber, user.id);
        }
      } else if (transaction.action === TRANSACTIONS.DEPOSIT) {
        this._errorTransaction(transaction, user);
      }
    }
  }

  /**
   * If deposit or cashout is success, data will be sent to gtm.
   * @transaction
   * @userInfo
   */
  private _successTransaction(transaction: any, userInfo) {
    const dataLayer = {
      event: transaction.action === TRANSACTIONS.CASHOUT ? 'withdraw_success' : 'deposit_success',
      event_category: CATEGORIES.FORM_SUBMIT,
      event_action: transaction.action === TRANSACTIONS.CASHOUT ? transaction.payment_system : 'deposit',
      event_label: transaction.action === TRANSACTIONS.CASHOUT ? 'cashout_success' : 'success',
      event_value: this._currency.centsToAmount(transaction.amount_cents, transaction.currency),
      dimension1: (userInfo.id).toString(),
      dimension2: userInfo.deposit_bonus_code ? userInfo.deposit_bonus_code : 'noBonus',
      dimension3: transaction.payment_system,
      dimension4: transaction.id,
      dimension5: transaction.currency,
    };
    if (transaction.action === TRANSACTIONS.CASHOUT) {
      delete dataLayer.dimension2;
      delete dataLayer.dimension3;
    }
    this._addDataLayer(dataLayer);
  }

  /**
   * If deposit or cashout is failed, data will be sent to gtm.
   * @transaction
   * @userInfo
   */
  private _errorTransaction(transaction: any, userInfo) {
    this._addDataLayer({
      event: transaction.action === TRANSACTIONS.CASHOUT ? 'withdraw_unsuccess' : 'deposit_unsuccess',
      event_category: transaction.action === TRANSACTIONS.CASHOUT ? 'withdraw_failure' : 'deposit_failure',
      event_action: transaction.payment_system,
      event_label: 'error_' + transaction.action,
      event_value: this._currency.centsToAmount(transaction.amount_cents, transaction.currency),
      dimension1: (userInfo.id).toString(),
      dimension4: transaction.id,
      dimension5: transaction.currency,
    });
  }

  /**
   * Check if transaction allready sended
   * @param transaction
   */
  private _checkExistTransaction(transaction: any) {
    if (this._cookie.check('tr-' + transaction.action) && this._cookie.get('tr-' + transaction.action) === transaction.id) {
      return false;
    } else {
      this._cookie.set('tr-' + transaction.action, transaction.id, 999, '/');
      return true;
    }
  }

  /**
   * Send deposit number
   * @param transaction
   * @param depNumber
   * @param userId
   */
  depositNumber(transaction: any, depNumber: number, userId: number) {
    const event = depNumber < 10 ? 'deposit_counter_' + depNumber : 'deposit_counter_whale';
    let label: string;
    switch (depNumber) {
      case 1:
        label = 'one';
        break;
      case 2:
        label = 'two';
        break;
      case 3:
        label = 'three';
        break;
      case 4:
        label = 'four';
        break;
      case 5:
        label = 'five';
        break;
      case 6:
        label = 'six';
        break;
      case 7:
        label = 'seven';
        break;
      case 8:
        label = 'eight';
        break;
      case 9:
        label = 'nine';
        break;
      default:
        label = 'whale';
    }

    this._addDataLayer({
      event: event,
      event_action: 'deposit',
      event_category: 'counter',
      event_label: label,
      dimension1: userId,
      dimension4: transaction.id
    });
  }

  /**
   * Send first deposit
   * @param time timestamp
   * @param userId
   */
  firstDeposit(time: number, userId: number) {
    if (+this._cookie.get(GTAG_COOKIES.FIRST_DEPOSIT_SENT) !== userId) {
      this._addDataLayer({
        event: 'dep_succ_time',
        event_action: time.toString(),
        event_category: 'timestamp',
        event_label: 'dep_succ',
        dimension1: userId
      });
      this._cookie.set(GTAG_COOKIES.FIRST_DEPOSIT_SENT, userId.toString(), 999, '/', (window as any).location.hostname);
    }
  }

  /**
   * Login button on game page click
   * @param {string} eventName
   * @param {string} label
   */
  public setAbTest(eventName: string, action: string, label: string) {
    if (label) {
      this._addDataLayer({
        event: eventName,
        event_action: action,
        event_category: CATEGORIES.ABTEST,
        event_label: label
      });
    }
  }

  /**
   * Send detected stag to gtm
   * @stag
   */
  public sendStag(stag, ref) {
    this._addDataLayer({
      event: 'stag',
      event_action: 'stag',
      event_category: 'first_pageview',
      event_label: ref ? ref : 'direct',
      dimension6: stag
    });
  }

  /**
   * Game click event
   * @param gameid
   */
  public gameClickEvent(gameid: string) {
    this._addDataLayer({
      event: 'game_click',
      gameid,
    });
  }
}
