/**
 * Available limit types
 */
export enum LimitType {
  LOSS = 'loss',
  WAGER = 'wager',
  DEPOSIT = 'deposit',
  COOLINGOFF = 'coolingoff',
  SELFEXCLUSION = 'selfexclusion',
  SESSION = 'session',
  REALITYCHECK = 'reality_check'
}

/**
 * Available limit statuses
 */
export enum LimitStatus {
  UNCONFIRMED_DISABLE = 'unconfirmed_disable',
  CONFIRMED_DISABLE = 'confirmed_disable',
  ACTIVE = 'active',
  UNCONFIRMED = 'unconfirmed',
  CONFIRMED = 'confirmed',
  CONFIRMED_DISABLED_NO_DATE = 'confirmed_disable_no_date', // this uses only for frontend
}

/**
 * Limit period parameter values
 */
export enum LimitPeriod {
  DAY = 'day',
  DAY_3 = 'day_3',
  WEEK = 'week',
  MONTH = 'month',
  MONTH_3 = 'month_3',
  MONTH_6 = 'month_6',
  MONTH_9 = 'month_9',
  MONTH_12 = 'month_12',
  FOREVER = 'forever'
}

/**
 * Status messages according by limit status
 */
export const StatusMessageList: any = {
  [LimitStatus.UNCONFIRMED_DISABLE]: 'limit.act-disable',
  [LimitStatus.CONFIRMED_DISABLE]: 'limit.act-will-disabled-date',
  [LimitStatus.CONFIRMED_DISABLED_NO_DATE]: 'limit.act-will-disabled',
  [LimitStatus.ACTIVE]: 'limit.act',
  [LimitStatus.UNCONFIRMED]: 'limit.unconfirm',
  [LimitStatus.CONFIRMED]: 'limit.confirm'
};


/**
 * Available period params
 */
export const PeriodMessageList: any = {
  [LimitPeriod.DAY]: 'limit.1-day',
  [LimitPeriod.DAY_3]: 'limit.3-day',
  [LimitPeriod.WEEK]: 'limit.1-week',
  [LimitPeriod.MONTH]: 'limit.1-month',
  [LimitPeriod.MONTH_3]: 'limit.3-month',
  [LimitPeriod.MONTH_6]: 'limit.6-month',
  [LimitPeriod.MONTH_9]: 'limit.9-month',
  [LimitPeriod.MONTH_12]: 'limit.1-year',
  [LimitPeriod.FOREVER]: 'limit.forever'
};

/**
 * Data arrays to pass in modal windows
 */
export const LimitModalData: any = {
  [LimitType.DEPOSIT]: {
    title: 'limit.dep',
    type: LimitType.DEPOSIT,
    description: 'Your account can be set with deposit limits. ' +
      'This setting limits the amount you can deposit per day, week or month. ' +
      'The limit becomes effective straight away. ' +
      'You can decrease your limit at any time and it will be effective immediately. ' +
      'You are free to increase the limit as well however the increase will take 7 days to become effective. ' +
      'Canceling the deposit limit will take 7 days to process. ' +
      'Once the limit is hit, you will receive the email notification.'
  },
  [LimitType.WAGER]: {
    title: 'limit.wager',
    type: LimitType.WAGER,
    description: 'Your account can be set with wager limits. ' +
      'This setting controls the amount of money you can wager per day, week or month. ' +
      'The limit becomes effective straight away. ' +
      'You can decrease your limit at any time and it will be effective immediately. ' +
      'You are free to increase the limit as well however the increase will take 7 days to become effective. ' +
      'Canceling the wager limit will take 7 days to process. ' +
      'Once the limit is hit, you will receive the email notification.'
  },
  [LimitType.LOSS]: {
    title: 'limit.loss',
    type: LimitType.LOSS,
    description: 'Your account can be set with loss limits. ' +
      'This setting limits the amount you can lose per day, week or month. ' +
      'The limit becomes effective straight away. ' +
      'You can decrease your limit at any time and it will be effective immediately. ' +
      'You are free to increase the limit as well however the increase will take 7 days to become effective. ' +
      'Canceling the loss limit will take 7 days to process. ' +
      'Once the limit is hit, you will receive the email notification.'
  },
};

/**
 * Data for limit accepting modal
 */
export const EditSessionLimitsModalData: any = {
  [LimitType.SESSION]: {
    title: 'limit.edit-session',
    description: 'You are able to set session limit in minutes. ' +
      'This setting controls the continuous period of time you can spend in a game. ' +
      'You are free to increase the limit as well however the increase will take 7 days to become effective. ' +
      'Canceling the session limit will take 7 days to process. ' +
      'Once the limit is hit you will be logged out from the system.'
  },
  [LimitType.COOLINGOFF]: {
    title: 'limit.edit-cooling',
    description: 'Felt the need to be apart from the casino for some time? ' +
      'Here you can set the Cooling-Off period for your player account for a week, a month, 3 or 6 months. ' +
      'The setting is applied immediately. ' +
      'During a Cooling-Off period you will be blocked from depositing to the casino ' +
      'and will be excluded from all promotional offers for the chosen period. ' +
      'Upon expiring, your account will be automatically re-activated and you will receive the email notification. ' +
      'Canceling the Cooling-Off period will take 7 days to process.'
  }
};

/**
 * Data for limit accepting modal
 */
export const AcceptLimitModalData: any = {
  [LimitType.COOLINGOFF]: {
    title: 'limit.want-set-cooling-off',
    description: 'Your account will be locked for #period#. You will not be able to play and make deposits during this period.'
  },
  [LimitType.SELFEXCLUSION]: {
    title: 'limit.want-lock-acc',
    description: 'Your account will be locked for #period#. You will not be able to log in to your account and perform any other actions.'
  }
};

/**
 * Values list for COOLINGOFF limit period selectbox
 */
export const CoolingOffPeriodValues: Array<any> = [
  {value: LimitPeriod.DAY, message: resolvePeriodMessage(LimitPeriod.DAY)},
  {value: LimitPeriod.DAY_3, message: resolvePeriodMessage(LimitPeriod.DAY_3)},
  {value: LimitPeriod.WEEK, message: resolvePeriodMessage(LimitPeriod.WEEK)},
  {value: LimitPeriod.MONTH_3, message: resolvePeriodMessage(LimitPeriod.MONTH_3)},
  {value: LimitPeriod.MONTH_6, message: resolvePeriodMessage(LimitPeriod.MONTH_6)},
];

/**
 * Values list for SELFEXCLUSION limit period selectbox
 */
export const SelfExclusionPeriodValues: Array<any> = [
  {value: LimitPeriod.MONTH_6, message: resolvePeriodMessage(LimitPeriod.MONTH_6)},
  {value: LimitPeriod.MONTH_9, message: resolvePeriodMessage(LimitPeriod.MONTH_9)},
  {value: LimitPeriod.MONTH_12, message: resolvePeriodMessage(LimitPeriod.MONTH_12)}
];

/**
 * Values list for REALITYCHECK limit period selectbox
 */
export const RealityCheckPeriodValues: Array<any> = [
  {value: 15, message: resolvePeriodMessage(15)},
  {value: 30, message: resolvePeriodMessage(30)},
  {value: 45, message: resolvePeriodMessage(45)},
  {value: 60, message: resolvePeriodMessage(60)},
];

/**
 * Returns correct period message for current period
 *
 * @param period
 */
export function resolvePeriodMessage(period): string {
  return PeriodMessageList[period] ?
    PeriodMessageList[period] :
    '#period# minutes';
}

/**
 * Default values for money limits
 */
export const MoneyLimitDefaultValues = {
  [LimitType.DEPOSIT]: { // Deposit limit default values
    [LimitPeriod.DAY]: {
      EUR: 10000,
      NOK: 100000,
      USD: 10000,
      AUD: 10000,
      CAD: 15000,
      NZD: 15000,
      PLN: 40000,
      ZAR: 150000,
      JPY: 1165000,
      HUF: 3500000,
      BTC: 1.3,
      BCH: 47,
      ETH: 69,
      LTC: 250,
      DOG: 4700000,
      USDT: 10000,
    },
    [LimitPeriod.WEEK]: {
      EUR: 70000,
      NOK: 700000,
      USD: 70000,
      AUD: 70000,
      CAD: 105000,
      NZD: 105000,
      PLN: 280000,
      ZAR: 1050000,
      JPY: 8160000,
      HUF: 24550000,
      BTC: 9,
      BCH: 325,
      ETH: 400,
      LTC: 1800,
      DOG: 33000000,
      USDT: 70000,
    },
    [LimitPeriod.MONTH]: {
      EUR: 300000,
      NOK: 3000000,
      USD: 300000,
      AUD: 300000,
      CAD: 450000,
      NZD: 450000,
      PLN: 1200000,
      ZAR: 4500000,
      JPY: 34990000,
      HUF: 105180000,
      BTC: 38,
      BCH: 1400,
      ETH: 1700,
      LTC: 7700,
      DOG: 140400000,
      USDT: 300000,
    }
  },
  [LimitType.WAGER]: { // Wager limit default values
    [LimitPeriod.DAY]: {
      EUR: 100000,
      NOK: 1000000,
      USD: 100000,
      AUD: 100000,
      CAD: 150000,
      NZD: 150000,
      PLN: 400000,
      ZAR: 1500000,
      JPY: 11660000,
      HUF: 35060000,
      BTC: 12.5,
      BCH: 461,
      ETH: 570,
      LTC: 2600,
      DOG: 45000000,
      USDT: 100000,
    },
    [LimitPeriod.WEEK]: {
      EUR: 700000,
      NOK: 7000000,
      USD: 700000,
      AUD: 700000,
      CAD: 1050000,
      NZD: 1050000,
      PLN: 2800000,
      ZAR: 10500000,
      JPY: 81640000,
      HUF: 245300000,
      BTC: 87,
      BCH: 3300,
      ETH: 4000,
      LTC: 18000,
      DOG: 310000000,
      USDT: 700000,
    },
    [LimitPeriod.MONTH]: {
      EUR: 3000000,
      NOK: 30000000,
      USD: 3000000,
      AUD: 3000000,
      CAD: 4500000,
      NZD: 4500000,
      PLN: 12000000,
      ZAR: 45000000,
      JPY: 350020000,
      HUF: 1051810000,
      BTC: 370,
      BCH: 13900,
      ETH: 17000,
      LTC: 76600,
      DOG: 1400000,
      USDT: 3000000,
    }
  },
  [LimitType.LOSS]: { // Loss limit default values
    [LimitPeriod.DAY]: {
      EUR: 10000,
      NOK: 100000,
      USD: 10000,
      AUD: 10000,
      CAD: 15000,
      NZD: 15000,
      PLN: 40000,
      ZAR: 150000,
      JPY: 1160000,
      HUF: 3500000,
      BTC: 1.3,
      BCH: 47,
      ETH: 69,
      LTC: 250,
      DOG: 4700000,
      USDT: 10000,
    },
    [LimitPeriod.WEEK]: {
      EUR: 70000,
      NOK: 700000,
      USD: 70000,
      AUD: 70000,
      CAD: 105000,
      NZD: 105000,
      PLN: 280000,
      ZAR: 1050000,
      JPY: 8160000,
      HUF: 24550000,
      BTC: 9,
      BCH: 325,
      ETH: 400,
      LTC: 1800,
      DOG: 33000000,
      USDT: 70000,
    },
    [LimitPeriod.MONTH]: {
      EUR: 300000,
      NOK: 3000000,
      USD: 300000,
      AUD: 300000,
      CAD: 450000,
      NZD: 450000,
      PLN: 1200000,
      ZAR: 4500000,
      JPY: 34990000,
      HUF: 105180000,
      BTC: 38,
      BCH: 1400,
      ETH: 1700,
      LTC: 7700,
      DOG: 140400000,
      USDT: 300000,
    }
  }
};
